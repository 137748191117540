/** @format */

import { takeLatest } from "redux-saga/effects";
import { TActionType } from "../declarations/actionTypes";
import { sagaCacheSave, sagaCacheRemove } from "../sagas/sagasCache";

export function* watcherCache(): unknown {
  yield takeLatest(TActionType.loginSuccess, sagaCacheSave);
  yield takeLatest(TActionType.logoutSuccess, sagaCacheRemove);
}
