/** @format */

export enum TActionType {
  loginRequest = "[Action] - Create Login Request",
  loginSuccess = "[Event] - Login Success",
  loginFailure = "[Event] - Login Failure",
  loginReset = "[Action] - Login Reset",

  logoutRequest = "[Action] - Create Logout Request",
  logoutSuccess = "[Event] - Logout Success",
  logoutFailure = "[Event] - Logout Failure",

  startCheckStatus = "[Action] - Start Api Key Check",
  successCheckStatus = "[Event] - Success Api Key Check",
  failureCheckStatus = "[Event] - Failure Api Key Check",
  endCheckStatus = "[Event] - End Api Key Check",

  cacheSaveSuccess = "[Event] - Save to Local Storage Success",
  cacheSaveFailure = "[Event] - Save to Local Storage Failure",

  cacheRemoveSuccess = "[Event] - Remove from Local Storage Success",
  cacheRemoveFailure = "[Event] - Remove from Local Storage Success",
}

export interface TAction<T = TActionType> {
  type: T;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: any;
}
