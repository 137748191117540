/** @format */

import { Checkbox, Col, Form, Row } from "antd";
import React from "react";
import { UIButton } from "../../ui/Button";
import { UICard } from "../../ui/Card";
import { UIInput } from "../../ui/Input";
import { useDispatch } from "react-redux";
import { actionLoginRequest } from "../../../redux-modules/actions/actionsAuth";
import { UITitle } from "../../ui/Title";
import { Colors } from "../../../theme/maps/Colors";
import { UserOutlined, UnlockOutlined } from "@ant-design/icons";
import { UIInputPassword } from "../../ui/Input/InputPassword";
import Layout from "antd/lib/layout/layout";

export const ViewLogin = (): JSX.Element => {
  const dispatch = useDispatch();
  console.log("login");
  const handleLogin = (loginObject: {}): void => {
    //@ts-ignore
    const authObj = { email: loginObject.email, password: loginObject.password };
    dispatch(actionLoginRequest(authObj));
  };

  return (
    <>
      <Layout style={{ height: "100%" }}>
        <Row style={{ backgroundColor: Colors.background }}>
          <Col span={24} style={{ textAlign: "center", padding: " 10px" }}>
            <UITitle level={2} color={Colors.black} style={{ fontWeight: 800, marginBottom: 0 }}>
              LOGIN
            </UITitle>
          </Col>
        </Row>
        <Row justify="center">
          <Col lg={8} sm={18} xs={24}>
            <UICard>
              <Form
                layout="vertical"
                name="basic"
                initialValues={{ remember: true }}
                onFinish={handleLogin}
                onFinishFailed={(e): void => console.log(e)}
              >
                <Form.Item
                  name="email"
                  rules={[{ required: true, message: "Inserisci un username valido!" }]}
                >
                  <UIInput placeholder="Username" prefix={<UserOutlined />} />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[{ required: true, message: "Inserisci una password valida!" }]}
                >
                  <UIInputPassword placeholder="Password" prefix={<UnlockOutlined />} />
                </Form.Item>
                <Form.Item style={{ display: "none" }}>
                  <Form.Item name="remember" valuePropName="checked" noStyle>
                    <Checkbox>Ricordami</Checkbox>
                  </Form.Item>
                </Form.Item>
                <Form.Item wrapperCol={{ span: 24 }}>
                  <UIButton
                    style={{ height: "55px", fontWeight: "bold", fontSize: "20px" }}
                    bgColor={Colors.background}
                    color={Colors.black}
                    hoverBgColor={Colors.background}
                    borderColor={Colors.background}
                    htmlType="submit"
                    fullWidth
                  >
                    ACCEDI
                  </UIButton>
                </Form.Item>
              </Form>
            </UICard>
          </Col>
        </Row>
      </Layout>
    </>
  );
};
