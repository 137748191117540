/** @format */

import { TUser } from "../declarations/maps/auth";
/** @format */

import { TStore } from "../declarations/store";

export const selectorAuthenticated = (store: TStore): boolean => !!store.auth?.user;

export const selectorLoggedUser = (store: TStore): TUser | undefined => store.auth.user;

// static TRUE
/* export const selectorAuthenticated = (store: TStore): boolean => false; */
