/** @format */

import {
  TActionLoginRequest,
  TActionLoginSuccess,
  TActionLoginFailure,
  TActionLogoutRequest,
  TActionLogoutSuccess,
  TActionLogoutFailure,
  TActionFailureApiKeyCheck,
  TActionSuccessApiKeyCheck,
  TActionStartApiKeyCheck,
  TActionEndApiKeyCheck,
  TActionLoginReset,
} from "../declarations/actions/auth";
import { TActionType } from "../declarations/actionTypes";
import { TAPILoginRequest } from "../declarations/apis/auth";
import { TUser } from "../declarations/maps/auth";

export const actionLoginRequest = (payload: TAPILoginRequest): TActionLoginRequest => ({
  type: TActionType.loginRequest,
  payload,
});

export const actionLoginSuccess = (payload: TUser): TActionLoginSuccess => ({
  type: TActionType.loginSuccess,
  payload: payload,
});

export const actionLoginFailure = (): TActionLoginFailure => ({
  type: TActionType.loginFailure,
});

export const actionLoginReset = (): TActionLoginReset => ({
  type: TActionType.loginReset,
});

export const actionLogoutRequest = (): TActionLogoutRequest => ({
  type: TActionType.logoutRequest,
});

export const actionLogoutSuccess = (): TActionLogoutSuccess => ({
  type: TActionType.logoutSuccess,
});

export const actionLogoutFailure = (): TActionLogoutFailure => ({
  type: TActionType.logoutFailure,
});

export const actionStartApiKeyCheck = (): TActionStartApiKeyCheck => ({
  type: TActionType.startCheckStatus,
});

export const actionSuccessApiKeyCheck = (): TActionSuccessApiKeyCheck => ({
  type: TActionType.successCheckStatus,
});

export const actionFailureApiKeyCheck = (): TActionFailureApiKeyCheck => ({
  type: TActionType.failureCheckStatus,
});

export const actionEndApiKeyCheck = (): TActionEndApiKeyCheck => ({
  type: TActionType.endCheckStatus,
});
