/** @format */

import React, { ReactChild, ReactChildren, useState, useEffect, createContext } from "react";
import { ThemeProvider } from "styled-components";
import { Colors } from "./maps/Colors";
import { TextSize } from "./maps/Size";
import { TextWeight } from "./maps/Weight";
import { FontFamily } from "./maps/FontFamily";
import { useLocation } from "react-router";
import { Routes } from "../utils/Routes";

const checkIsMobile = {
  Android: function (): RegExpMatchArray | null {
    return navigator.userAgent.match(/Android/i);
  },
  BlackBerry: function (): RegExpMatchArray | null {
    return navigator.userAgent.match(/BlackBerry/i);
  },
  iOS: function (): RegExpMatchArray | null {
    return navigator.userAgent.match(/iPhone|iPod/i); // can add also iPad
  },
  Opera: function (): RegExpMatchArray | null {
    return navigator.userAgent.match(/Opera Mini/i);
  },
  Windows: function (): RegExpMatchArray | null {
    return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
  },
  any: function (): RegExpMatchArray | null {
    return (
      checkIsMobile.Android() ||
      checkIsMobile.BlackBerry() ||
      checkIsMobile.iOS() ||
      checkIsMobile.Opera() ||
      checkIsMobile.Windows()
    );
  },
};

const theme = {
  colors: Colors,
  textSize: TextSize,
  textWeight: TextWeight,
  fontFamily: FontFamily,
  isMobile: !!checkIsMobile.any(),
};

export type themeType = typeof theme;
export const CustomThemeContext = createContext(theme);

interface Props {
  children: ReactChild | ReactChildren;
}

const Theme = ({ children }: Props): JSX.Element => {
  const [currentTheme, setCurrentTheme] = useState(theme);
  const location = useLocation();

  useEffect(() => {
    const handleResize = (): void => {
      const isMobile = !!checkIsMobile.any();
      console.log(isMobile);
      setCurrentTheme({ ...currentTheme, isMobile: !!isMobile });

      const heightDiff = location.pathname === Routes.home ? -80 : 0;
      //@ts-ignore
      document.getElementById("root").style.height =
        //@ts-ignore
        window.innerHeight + heightDiff + "px";
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleResize = (): void => {
      const isMobile = !!checkIsMobile.any();
      console.log(isMobile);
      setCurrentTheme({ ...currentTheme, isMobile: !!isMobile });

      const heightDiff = location.pathname === Routes.home ? -80 : 0;
      //@ts-ignore
      document.getElementById("root").style.height =
        //@ts-ignore
        window.innerHeight + heightDiff + "px";
    };
    handleResize();
  }, [location]);

  return (
    <CustomThemeContext.Provider value={currentTheme}>
      <ThemeProvider theme={currentTheme}>
        <CustomThemeContext.Consumer>
          {(value): ReactChild | ReactChildren => children}
        </CustomThemeContext.Consumer>
      </ThemeProvider>
    </CustomThemeContext.Provider>
  );
};
export default Theme;
