/** @format */

import { AxiosError, AxiosResponse } from "axios";
import qs from "qs";
import { API_TASKS } from "../../constants/api";
/* import { TAPILoginSuccess } from "../../redux-modules/declarations/apis/auth";
 */ import apiClient from "../../utils/apiClient";

interface Params {
  username: string;
  password: string;
}

export const apiLogin = (data: Params): Promise<{ response?: AxiosResponse; error?: AxiosError }> =>
  apiClient
    .post("", qs.stringify({ ...data, task: API_TASKS.LOGIN }))
    .then(response => ({ response }))
    .catch(error => ({ error }));

//FAKE LOGIN
/* export const apiLogin = (data: Params): TAPILoginSuccess => ({
  data: {
    esito: "ok",
    id: 10,
  },
}); */
