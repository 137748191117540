/** @format */

//const PROXY_URL = "https://cors-anywhere.herokuapp.com/";

export const API_PARAMS = {
  BASE_URL: process.env.REACT_APP_API_URL,
};

export const API_TASKS = {
  BARCODE: "BARCODE",
  GET_USER_APPIDAYS: "GET_UTENTE_APPIDAYS",
  ADD_IMPORTO: "ADD_IMPORTO",
  LOGIN: "GET_UTENTE",
  GET_FIDO: "GET_FIDO",
  GET_CODICE_AZIENDA: "GET_CODICE_AZIENDA",
  GET_CAUSALI: "GET_CAUSALI",
  GET_SEARCH: "GET_SEARCH",
  ADD_MOVIMENTAZIONE: "ADD_MOVIMENTAZIONE",
};
