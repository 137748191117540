/** @format */

import { Store, createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { utilityGetFromLocalStorage } from "../utils/cache/getFromLocalStorage";
import { reducers } from "./reducers";
import { middlewares } from "./middlewares";
import { middlwareSaga } from "./middlewares/middlewareSaga";
import { watchers } from "./watchers";
import { KEY_APP_NAME } from "../constants/general";
import { TStore } from "./declarations/store";

export const configureStore = (): Store => {
  const prevStore: TStore = utilityGetFromLocalStorage(KEY_APP_NAME);
  const enhancer = composeWithDevTools({ trace: true, traceLimit: 25 });
  const store = createStore(reducers, prevStore, enhancer(applyMiddleware(...middlewares)));

  //@ts-ignore
  watchers.forEach(watcher => middlwareSaga.run(watcher));

  return store;
};
