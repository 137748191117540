/** @format */

import { Row, Col } from "antd";
import Layout, { Header } from "antd/lib/layout/layout";
import React, { useEffect } from "react";
import { NavLink, Switch, useLocation } from "react-router-dom";
import "./App.less";
import { Routes } from "./utils/Routes";
import logo from "./assets/logo/brugnanoenergiawhite.jpg";
import { RouteGuest } from "./components/utils/RouteGuest";
import { ViewLogin } from "./components/views/ViewLogin";
import { selectorAuthenticated } from "./redux-modules/selectors/selectorsAuth";
import { useSelector } from "react-redux";
/* import moment from "moment";
 */ import { RouteLogged } from "./components/utils/RouteLogged";
import { ViewHome } from "./components/views/ViewHome";
/* import { actionStartApiKeyCheck } from "./redux-modules/actions/actionsAuth";
 */ import { ViewSearch } from "./components/views/ViewSearch";
import { ViewForm } from "./components/views/ViewForm";

function App(): JSX.Element {
  const location = useLocation();
  const isAuthenticated = useSelector(
    selectorAuthenticated,
  ); /* 
  useEffect(() => {
    moment().locale("it");
    dispatch(actionStartApiKeyCheck());
  }, []);
 */
  /*   const dispatch = useDispatch();
   */ useEffect(() => {
    console.log(isAuthenticated);
  }, [isAuthenticated]);

  return (
    <>
      <Layout>
        <Header
          className="header"
          style={{
            height: "80px",
            display: !!isAuthenticated && location.pathname === Routes.home ? "inherit" : "none",
            padding: "0 10px",
          }}
        >
          <Row justify="center" style={{ width: "100%" }}>
            <Col span={5}> </Col>
            <Col span={14} style={{ textAlign: "center", alignSelf: "center" }}>
              <NavLink to={Routes.home}>
                <img src={logo} alt="logo" />
              </NavLink>
            </Col>
            <Col span={5} style={{ textAlign: "center", alignSelf: "flex-end" }}></Col>
          </Row>
        </Header>
      </Layout>
      <>
        <Switch location={location}>
          <RouteLogged path={Routes.home} exact component={ViewHome} />
          <RouteLogged path={Routes.search} exact component={ViewSearch} />
          <RouteLogged path={Routes.form} exact component={ViewForm} />
          <RouteGuest path="/" component={ViewLogin} />
        </Switch>
      </>
    </>
  );
}
export default App;
