/** @format */

import { Col, Layout, Row } from "antd";
import React from "react";
import { Colors } from "../../../theme/maps/Colors";
import { UIButton } from "../../ui/Button";
import LogoutLogo from "../../../assets/logo/logout.svg";
import { UIText } from "../../ui/Text";
import { Footer } from "antd/lib/layout/layout";
import { useDispatch } from "react-redux";
import { TActionLogoutRequest } from "../../../redux-modules/declarations/actions/auth";
import { actionLogoutRequest } from "../../../redux-modules/actions/actionsAuth";
import { NavLink } from "react-router-dom";
import { Routes } from "../../../utils/Routes";

export const ViewHome = (): JSX.Element => {
  const dispatch = useDispatch();
  const handleLogout = (): TActionLogoutRequest => dispatch(actionLogoutRequest());

  return (
    <>
      <Layout className="site-layout-background" style={{ padding: "24px 0", height: "100%" }}>
        <Row justify="center" style={{ height: "100%" }}>
          <Col span={12} style={{ alignSelf: "center" }}>
            <NavLink to={Routes.search}>
              <UIButton
                style={{ height: "55px", fontWeight: "bold", fontSize: "20px" }}
                bgColor={Colors.background}
                color={Colors.black}
                hoverBgColor={Colors.background}
                borderColor={Colors.background}
                fullWidth
              >
                BUONI
              </UIButton>
            </NavLink>
          </Col>
        </Row>
        <Footer style={{ padding: "24px 24px 0 24px" }}>
          <Row justify="end" style={{ minHeight: "100px" }}>
            <Col
              onClick={handleLogout}
              style={{ alignSelf: "flex-end", padding: "10px", cursor: "pointer" }}
            >
              <div>
                <img style={{ width: "32px" }} src={LogoutLogo} alt="logout" />
              </div>
              <UIText strong style={{ fontSize: "16px", color: Colors.background }}>
                Esci
              </UIText>
            </Col>
          </Row>
        </Footer>
      </Layout>
    </>
  );
};
