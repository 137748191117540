/** @format */

import { put, call, select } from "redux-saga/effects";
import { TActionLoginRequest } from "../declarations/actions/auth";
import {
  actionLoginSuccess,
  actionLoginFailure,
  actionLogoutFailure,
  actionLogoutSuccess,
  actionLogoutRequest,
  actionFailureApiKeyCheck,
  actionEndApiKeyCheck,
  actionStartApiKeyCheck,
} from "../actions/actionsAuth";
import { apiLogout } from "../../api/auth/apiLogout";
import { apiLogin } from "../../api/auth/apiLogin";
import { utilitySaveToLocalStorage } from "../../utils/cache/saveToLocalStorage";
import { utilityGetFromLocalStorage } from "../../utils/cache/getFromLocalStorage";
import { selectorLoggedUser } from "../selectors/selectorsAuth";

export function* sagaLogin(action: TActionLoginRequest): unknown {
  const response = yield call(apiLogin, {
    username: action.payload.email,
    password: action.payload.password,
  });
  console.log(response.response.data);
  if (response && !!response.response.data.ID) {
    const userId = response.response.data.ID;
    const userData = response.response.data;

    utilitySaveToLocalStorage("userId", userId);
    yield put(actionLoginSuccess(userData));
    yield put(actionStartApiKeyCheck());
  } else {
    yield put(actionLoginFailure());
  }
}

export function* sagaLogout(): unknown {
  try {
    try {
      yield call(apiLogout);
    } catch (e) {
      console.error(e.stack);
    }
    yield put(actionLogoutSuccess());
  } catch {
    yield put(actionLogoutFailure());
  }
}

export function* sagaCheckApiToken(): unknown {
  while (true) {
    try {
      const userUid = yield select(selectorLoggedUser);
      if (!userUid) return;
      const loggedId = utilityGetFromLocalStorage("userId");
      if (!loggedId) {
        yield put(actionLogoutRequest());
        yield put(actionEndApiKeyCheck());
        return;
      }
      //yield put(actionSuccessApiKeyCheck());
    } catch (e) {
      yield put(actionFailureApiKeyCheck());
    }
  }
}
