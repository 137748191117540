/** @format */

import React, { memo, FC } from "react";
import { Route } from "react-router";
import { Redirect } from "react-router-dom";
import { Routes } from "../../utils/Routes";
import { useSelector } from "react-redux";
import { selectorAuthenticated } from "../../redux-modules/selectors/selectorsAuth";

interface Props {
  path: string;
  component: FC;
  exact?: boolean;
  strict?: boolean;
}

export const RouteGuest: FC<Props> = memo(
  ({ component: Component, path, exact, strict }: Props): JSX.Element => {
    const isAuthenticated = useSelector(selectorAuthenticated);

    return (
      <Route
        exact={exact}
        strict={strict}
        path={path}
        render={(props): JSX.Element =>
          !isAuthenticated ? (
            // @ts-ignore
            <Component {...props} />
          ) : (
            <Redirect to={Routes.home} />
          )
        }
      />
    );
  },
);
RouteGuest.displayName = "RouteGuest";
