/** @format */

import React from "react";
import ReactDOM from "react-dom";
import { ConfigProvider } from "antd";
import itIT from "antd/es/locale/it_IT";
import moment from "moment";
import "./index.css";
import "./normalize.css";

import App from "./App";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { configureStore } from "./redux-modules/configureStore";
import Theme from "./theme/Theme";
import PrintProvider, { NoPrint } from "react-easy-print";

moment.locale("it");

const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <ConfigProvider locale={itIT}>
      <Router>
        <Theme>
          <PrintProvider>
            <NoPrint>
              <App />
            </NoPrint>
          </PrintProvider>
        </Theme>
      </Router>
    </ConfigProvider>
  </Provider>,
  document.getElementById("root"),
);
